import React from 'react';
import PropTypes from 'prop-types';
import useVerticalIndex from '@/src/hooks/useVerticalIndex';
import AnchorLink from '@components/AnchorLink';
import RenderAnimation from '@components/RenderAnimation';
import { buildContentfulImageURL } from '@utils/image';
import Image from '../Image';
import animationData from '../../animations/scroll_indicator.json';
import Gambit from '../../pages/Article/components/ArticleGambit';
import VideoHero from '../VideoHero';
import { trackClickEvent } from '../../utils/analytics';
import s from './Hero.module.scss';
import Button from '../Button';

const propTypes = {
  eyebrow: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  image2x: PropTypes.string,
  mobileImage: PropTypes.string,
  children: PropTypes.node,
  large: PropTypes.bool,
  tall: PropTypes.bool,
  small: PropTypes.bool,
  parallax: PropTypes.bool,
  gold: PropTypes.bool,
  descriptionTitle: PropTypes.string,
  descriptionText: PropTypes.string,
  displayLogo: PropTypes.shape({}),
  scrollIndicator: PropTypes.bool,
  themed: PropTypes.bool,
  heroDepth: PropTypes.oneOf(['shallow', 'extra-shallow', 'deep']),
  mainVideo: PropTypes.shape({
    videoFileSource: PropTypes.string,
  }),
  modalHero: PropTypes.bool,
  ctaUrl: PropTypes.string,
  ctaLabel: PropTypes.string,
};

const Hero = ({
  eyebrow = '',
  title = '',
  subtitle = '',
  image = '',
  image2x = '',
  mobileImage = '',
  children = [],
  large = false,
  tall = false,
  small = false,
  parallax = false,
  gold = false,
  descriptionTitle = '',
  descriptionText = '',
  displayLogo = {},
  scrollIndicator = false,
  themed = false,
  heroDepth = 'deep',
  mainVideo = null,
  modalHero = false,
  ctaLabel = '',
  ctaUrl = '',
}) => {
  const { componentRef, verticalIndex } = useVerticalIndex();
  const [videoError, setVideoError] = React.useState(false);
  const deep = heroDepth === 'deep';
  const extraShallow = heroDepth === 'extra-shallow';
  const heroVideo = mainVideo?.videoFileSource ?? null;

  const displayLogoUrl = displayLogo?.file?.url;
  const imageSet = [
    {
      src: `${buildContentfulImageURL(displayLogoUrl, {
        width: '480',
        fit: 'fill',
        format: 'webp',
      })} 1x, ${buildContentfulImageURL(displayLogoUrl, {
        width: '960',
        fit: 'fill',
        format: 'webp',
      })} 2x, ${buildContentfulImageURL(displayLogoUrl, {
        width: '1440',
        fit: 'fill',
        format: 'webp',
      })} 3x`,
      media: '(max-width: 480px)',
      type: 'image/webp',
    },
    {
      src: `${buildContentfulImageURL(displayLogoUrl, {
        width: '480',
        fit: 'fill',
        format: 'jpg',
      })} 1x, ${buildContentfulImageURL(displayLogoUrl, {
        width: '960',
        fit: 'fill',
        format: 'jpg',
      })} 2x, ${buildContentfulImageURL(displayLogoUrl, {
        width: '1440',
        fit: 'fill',
        format: 'jpg',
      })} 3x`,
      media: '(max-width: 480px)',
      type: 'image/jpeg',
    },
    {
      src: `${buildContentfulImageURL(displayLogoUrl, {
        width: '1024',
        fit: 'fill',
      })} 1x, ${buildContentfulImageURL(displayLogoUrl, {
        width: '2048',
        fit: 'fill',
      })} 2x`,
      media: '(max-width: 1024px)',
      type: 'image/webp',
    },
    {
      src: `${buildContentfulImageURL(displayLogoUrl, {
        width: '1024',
        fit: 'fill',
        format: 'jpg',
      })} 1x, ${buildContentfulImageURL(displayLogoUrl, {
        width: '2048',
        fit: 'fill',
        format: 'jpg',
      })} 2x`,
      media: '(max-width: 1024px)',
      type: 'image/jpeg',
    },
    {
      src: `${buildContentfulImageURL(displayLogoUrl, {
        width: '1280',
      })} 1x, ${buildContentfulImageURL(displayLogoUrl, {
        width: '2560',
      })} 2x`,
      type: 'image/webp',
    },
    {
      src: `${buildContentfulImageURL(displayLogoUrl, {
        width: '1280',
        format: 'jpg',
      })} 1x, ${buildContentfulImageURL(displayLogoUrl, {
        width: '2560',
        format: 'jpg',
      })} 2x`,
      type: 'image/jpeg',
    },
    {
      src: buildContentfulImageURL(displayLogoUrl, { width: '1280' }),
    },
  ];

  return (
    <div
      className={[
        'hero-wrapper',
        s.wrapper,
        !image ? s.noImage : '',
        s[heroDepth],
        parallax ? s.parallax : '',
        themed ? s.themed : '',
        heroVideo ? s.hasVideo : '',
      ].join(' ')}
      style={{
        '--background-image':
          image && (!heroVideo || (heroVideo && videoError)) ? `url(${image})` : 'none',
        '--background-image-2x':
          image2x && (!heroVideo || (heroVideo && videoError)) ? `url(${image2x})` : 'none',
        '--background-image-mobile':
          mobileImage && (!heroVideo || (heroVideo && videoError))
            ? `url(${mobileImage})`
            : `url(${image})`,
      }}
      role="complementary"
      ref={componentRef}
    >
      {heroVideo && (
        <div className={s.videoBackground}>
          <VideoHero
            src={heroVideo}
            onVideoError={() => {
              setVideoError(true);
            }}
          />
        </div>
      )}
      <div
        className={[
          'hero-content',
          s.content,
          modalHero ? s.modalHero : '',
          large ? s.large : '',
          tall ? s.tall : '',
          small ? s.small : '',
        ].join(' ')}
      >
        {displayLogo?.file?.url && (
          <div className={s.logo}>
            <Image imageSet={imageSet} alt="Hero logo" contain />
          </div>
        )}
        {deep && eyebrow ? <div className={s.eyebrow}>{eyebrow}</div> : ''}
        <h1 className={[s.h1, s.center, gold ? s.gold : ''].join(' ')}>{title}</h1>
        <div className={s.subtitle}>{subtitle}</div>
        {parallax && <div className={s.heroChildren}>{children}</div>}
        {deep && scrollIndicator && (
          <div className={s.scrollIndicator}>
            <RenderAnimation
              animation="scroll-indicator"
              animationData={animationData}
              ariaLabel="Animated scroll indicator"
            />
          </div>
        )}
        {!parallax && <>{children}</>}
        {deep && descriptionTitle && <div className={s.descriptionTitle}>{descriptionTitle}</div>}
        {descriptionText && (
          <div className={[s.descriptionText, 'hero-description'].join(' ')}>
            {deep && <Gambit intro={descriptionText} theme="dark" size="small" />}
            {!deep && descriptionText}
          </div>
        )}
        {!extraShallow && ctaLabel && ctaUrl && (
          <AnchorLink href={ctaUrl} className={s.cta}>
            <Button
              type="secondary"
              label={ctaLabel}
              onClick={() => {
                trackClickEvent({
                  destination_url: ctaUrl,
                  label: ctaLabel,
                  location: 'Hero',
                  vertical_index: verticalIndex,
                  horizontal_index: 0,
                });
              }}
            />
          </AnchorLink>
        )}
      </div>
    </div>
  );
};

Hero.propTypes = propTypes;
export default Hero;
